@import '../utils/base';

.home {
  text-align: center;
  height: calc(100vh - #{$headerHeight} - #{($footer-height + $footer-margin)});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.home__title {
  font-size: 1.75rem;

  @media (min-width: $mdMin) {
    font-size: 2.2rem;
  }
}
