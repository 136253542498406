@import './utils/base';

html,
body,
#root {
  height: 100%;
}

* {
  outline: none !important;
}

.bg-main {
  background-color: $mainColor !important;
}

.dropdown-item:not(:disabled) {
  cursor: pointer;
}

.dropdown-item.active:not(:disabled),
.dropdown-item:active:not(:disabled) {
  background-color: $lightGrey !important;
  color: inherit !important;
}

.badge-main {
  color: #ffffff;
  background-color: $mainColor;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  display: block !important;
}

.navbar-brand {
  @media (max-width: $smMax) {
    margin: 0 auto !important;
  }
}

.pagination .page-link {
  cursor: pointer;
}

.indivisible {
  white-space: nowrap;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.react-datepicker__day--keyboard-selected {
  background-color: $mainColor;

  &:hover {
    background-color: darken($mainColor, 12%);
  }
}

.progress-bar {
  background-color: $mainColor;
}
